<template>
    <div class="dwrap">
        <div>
            <p>{{ this.message }}</p>
        </div>
    </div>
</template>
<script>

    import { mapMutations, mapActions, mapGetters } from "vuex"
    import router from "@/router"

    export default {
        props: {
            span: [Number]
        },
        data() {
            return {
                message: 'Payment failed!'
            }
        },
        methods: {
            
        },
        mounted() {
            
        }
    }
</script>

<style type="text/css">
    .dwrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .dwrap > div {
        background: white;
        max-width: 100%;
        width: 300px;
        text-align: center;
        padding: 1em 0.25em;
        margin: 0 auto;
    }
</style>